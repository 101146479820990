import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Box } from '@mui/material';
import HomePage from './HomePage';
import LoadingModal from './klondike/UI/LoadingModal';

const KlondikeGame = React.lazy(() => import('./klondike/components/Game'));

function App() {
  return (
    <Box className="App">
      <Router>
        <Routes>
          <Route index element={<HomePage />} />
          <Route
            path="/klondike"
            element={
              <React.Suspense fallback={<LoadingModal />}>
                <KlondikeGame />
              </React.Suspense>
            }
          />
        </Routes>
      </Router>
    </Box>
  );
}

export default App;
