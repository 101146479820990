import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router';

const HomePage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Button variant="contained" onClick={() => navigate('/klondike')}>
        Косынка
      </Button>
    </div>
  );
};

export default React.memo(HomePage);
