import React from 'react';
import { Dialog, DialogTitle } from '@mui/material';

const LoadingModal: React.FC = () => {
  return (
    <Dialog open={true} fullWidth>
      <DialogTitle
        sx={{
          textAlign: 'center',
        }}
      >
        Загрузка...
      </DialogTitle>
    </Dialog>
  );
};

export default React.memo(LoadingModal);
